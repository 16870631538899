import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Box, IconButton, CircularProgress, styled, keyframes} from '@mui/material';
import useSettings from '../hooks/useSettings';
import Iconify from './Iconify';
import { IconButtonAnimate } from './animate'
import { useTheme } from '@mui/material/styles';

//eva:loader-outline
const rotateScale = keyframes`
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(0.5);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
`;

const ChangeTheme = forwardRef(({ isOffset, isHome }, ref) => {
  //alert(isOffset, isHome);
  const theme = useTheme();

  

  const { themeMode, onToggleMode } = useSettings();
  const [isAnimating, setIsAnimating] = useState(false);
  const [icon, setIcon] = useState(themeMode === 'dark' ? 'eva:moon-outline' : 'eva:sun-outline');

  //const iconColor = isOffset ? 'text.primary' : themeMode === 'dark' ? theme.palette.grey[0] : theme.palette.grey[900];

  const iconColor = isOffset ? 'text.primary' : (isHome ? 'common.white' : 'text.primary');


  useImperativeHandle(ref, () => ({
    handleIconClick,
  }));
  const handleIconClick = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      setTimeout(() => {
        // Смена иконки в середине анимации
        onToggleMode();
        setIcon(themeMode === 'dark' ? 'eva:sun-outline' : 'eva:moon-outline');
      }, 300); // Половина времени анимации

      setTimeout(() => {
        setIsAnimating(false);
      }, 600); // Время для полной анимации
    }
  };

  return (
    <Box sx={{ textAlign: 'center', mx: 2.5, marginBottom: 'auto'}}>
      <IconButtonAnimate
        onClick={handleIconClick}
        disabled={isAnimating}
        sx={{
          animation: isAnimating ? `${rotateScale} 0.6s ease-in-out forwards` : 'none',
          color: iconColor,
          transition: 'color 0.3s',
          
          
        }}
        size='small'

        // color='primary'
      >
        <Iconify icon={icon} />
      </IconButtonAnimate>
    </Box>
  );
});

export default ChangeTheme;
