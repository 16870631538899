// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function ComingSoonIllustration({ ...other }) {
  const theme = useTheme();


  return (
    <Box {...other}  >
       <svg xmlns='http://www.w3.org/2000/svg' width="50%"  viewBox="0 0 402.04 256">
        <defs>
          <linearGradient id='grad1' x1='50%' y1='0%' x2='50%' y2='100%'>
            {/* <stop offset='0%' stopColor={theme.palette.primary.dark} /> */}
            <stop offset='0%' stopColor={theme.palette.primary.main} />
            <stop offset='100%' stopColor={theme.palette.primary.dark} />
          </linearGradient>
          <linearGradient id='grad2' x1='50%' y1='100%' x2='50%' y2='0%'>
            <stop offset='0%' stopColor={theme.palette.primary.main} />
            <stop offset='100%' stopColor={theme.palette.primary.dark} />
          </linearGradient>
          <filter id='shadow' x='-20%' y='-20%' width='140%' height='140%'>
            <feGaussianBlur in='SourceAlpha' stdDeviation='2' />
            <feOffset dx='-3' dy='3' result='offsetblur' />
            <feMerge>
              <feMergeNode />
              <feMergeNode in='SourceGraphic' />
            </feMerge>
          </filter>
        </defs>
        <g
          fillRule='evenodd'
          stroke={theme.palette.text.secondary}
          strokeWidth='1'

          filter='url(#shadow)'
        >
          <path
            d="M380.6,47.54c21.8,38.56,27.24,80.56,15.11,124.92-13.2,48.26-42.11,76.47-82.34,81.47-32.65,4.06-65.91.83-99.56.83V.75c1.84-.17,4.07-.59,6.3-.55,31.28.62,62.68-.48,93.78,2.77,14.38,1.51,28.18,11.53,42.24,17.69,8.16,8.96,16.32,17.92,24.48,26.88ZM343.79,81.61c-6.28-15.06-16.35-24.74-28.95-30.79-17.3-2.37-34.6-4.73-52.74-7.22v166.18c14.17,0,27.81,1.11,41.28-.29,15.34-1.59,28.55-10.31,36.69-26.85,16.04-32.6,16.03-66.59,3.72-101.02h0Z"
            fill='url(#grad1)'
          />
          <path
            d="M0,.38C15.36.38,29.87-.8,44.13,1.01c5.13.65,11.61,7.27,14.11,13.28,13.55,32.59,26.04,65.84,38.92,98.85.48,1.24,1.2,2.35,2.17,4.23,12.16-31.18,24.06-61.71,35.98-92.23,1.62-4.16,3.21-8.35,4.97-12.42C143.84,4.44,149.39.12,157.12.15c13.33.06,26.67.02,41.28.02-4.58,9.92-8.7,19.07-12.98,28.1-19.67,41.52-39.45,82.96-58.96,124.59-1.9,4.05-3.07,9.21-3.11,13.88-.27,29.56-.14,59.12-.14,89.26h-48.44c0-11.73.05-23.35-.01-34.97-.11-21.04,2.94-43.15-1.37-62.81-4.22-19.24-15.95-35.98-24.41-53.8C33.51,71.84,18.05,39.27,2.6,6.68,1.77,4.94,1.11,3.09,0,.38Z"
            fill='url(#grad2)'
          />
        </g>
      </svg>
    </Box>
  );
}
