import logo from "./logo.svg";
import "./App.css";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Stack,
  Container,
  Typography,
  InputAdornment,
  IconButton, // Добавлено для иконки кнопки
  Chip,
} from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";
import TelegramIcon from "@mui/icons-material/Telegram"; // Иконка Telegram
import React, { useState, useEffect, useRef } from "react";
// hooks
import useCountdown from "./hooks/useCountdown";
// components
import Page from "./components/Page";
import InputStyle from "./components/InputStyle";

 import ChangeTheme from "./components/ChangeTheme";
import { LoadingButton } from "@mui/lab";
// import SocialsButton from '../components/SocialsButton';
// assets
import { ComingSoonIllustration } from "./assets";
import ThemeProvider from "./theme";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import useSettings from "./hooks/useSettings";


import Settings from "./components/settings";
import ThemeColorPresets from "./components/ThemeColorPresets";




const schema = yup
  .object({
    email: yup
      .string()
      .email("Введите корректный email")
      .required("Email обязателен к заполнению"),
  })
  .required();

const RootStyle = styled("div")(({ theme }) => ({
  //  height: "100%",
  "@media (min-height: 850px)": {
    height: "100vh", // Устанавливаем высоту только если высота устройства более 850px
  },
  display: "flex",
  alignItems: "center",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(15),
}));

const CountdownStyle = styled("div")({
  display: "flex",
  justifyContent: "center",
});

const SeparatorStyle = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 1),
  [theme.breakpoints.up("sm")]: {
    margin: theme.spacing(0, 2.5),
  },
}));

function App() {
  const countdown = useCountdown(new Date("09/01/2024 10:30"));
  const [responseMessage, setResponseMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [subscriberCount, setSubscriberCount] = useState(0);

  const themeChangeRef = useRef(null);

  const changeThemeAfterSuccess = () => {
    // Предположим, это вызывается, когда данные успешно получены
    if (themeChangeRef.current) {
      themeChangeRef.current.handleIconClick();
    }
  };



  //  onToggleMode();



  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const handleNotify = (data) => {
    setIsLoading(true);
    fetch("/api/save-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "error") {
          setErrorMessage(data.message);
          setResponseMessage(null);
        } else {
          setResponseMessage(data.message);
          setErrorMessage(null);
          reset(); // Сброс формы
          changeThemeAfterSuccess();
          setSubscriberCount((prevCount) => prevCount + 1); // Увеличение счетчика подписчиков
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Ошибка:", error);
        setResponseMessage(null);
        setErrorMessage("Произошла ошибка при отправке данных на сервер.");
        setIsLoading(false);
      });
  };

  // Получение количества подписчиков при загрузке компонента
  useEffect(() => {
    fetch("/api/count-email")
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setSubscriberCount(data.count);
        } else {
          console.error("Ошибка при получении количества подписчиков");
        }
      })
      .catch((error) => console.error("Ошибка:", error));
  }, []);

  return (

      <ThemeProvider>
        <ThemeColorPresets>

            <Page title="Твой день | Старт проекта через..." sx={{ height: 1 }}>
              <RootStyle>
                <Container>
              
                  <Box
                    sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}
                  >
                     
                    <Typography variant="h3" paragraph>
                      Скоро здесь что-то будет...
                    </Typography>
                   
                    <Box sx={{ mt: 2, textAlign: "center" }}>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography sx={{ color: "text.secondary" }}>
                          Новый проект для исполнителей
                        </Typography>
                        <Chip
                          color="primary"
                          label={`🎉 ${subscriberCount} уже с нами!`}
                        />
                        <ChangeTheme ref={themeChangeRef}/>
                      </Stack>
                    </Box>
                    
  

                    <ComingSoonIllustration sx={{ my: 10 }} />
                    
                    {/* <ComingSoonIllustration  /> */}

                    <CountdownStyle>
                      <div>
                        <Typography variant="h3">{countdown.days}</Typography>
                        <Typography sx={{ color: "text.secondary" }}>
                          Дней
                        </Typography>
                      </div>

                      <SeparatorStyle variant="h3">:</SeparatorStyle>

                      <div>
                        <Typography variant="h3">{countdown.hours}</Typography>
                        <Typography sx={{ color: "text.secondary" }}>
                          Часов
                        </Typography>
                      </div>

                      <SeparatorStyle variant="h3">:</SeparatorStyle>

                      <div>
                        <Typography variant="h3">
                          {countdown.minutes}
                        </Typography>
                        <Typography sx={{ color: "text.secondary" }}>
                          Минут
                        </Typography>
                      </div>

                      <SeparatorStyle variant="h3">:</SeparatorStyle>

                      <div>
                        <Typography variant="h3">
                          {countdown.seconds}
                        </Typography>
                        <Typography sx={{ color: "text.secondary" }}>
                          Секунд
                        </Typography>
                      </div>
                    </CountdownStyle>
                    <form onSubmit={handleSubmit(handleNotify)}>
                      <InputStyle
                        fullWidth
                        placeholder="Email"
                        {...register("email")}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <LoadingButton
                                loading={isLoading}
                                type="submit" // Добавлено для отправки формы
                                variant="contained"
                                size="large"
                              >
                                Подписаться
                              </LoadingButton>
                            </InputAdornment>
                          ),
                        }}
                        sx={{ my: 5, "& .MuiOutlinedInput-root": { pr: 0.5 } }}
                      />

                      {(responseMessage || errorMessage) && (
                        <Stack spacing={2} sx={{ mb: 5 }}>
                          {responseMessage && (
                            <Alert severity="success">{responseMessage}</Alert>
                          )}
                          {errorMessage && (
                            <Alert severity="error">{errorMessage}</Alert>
                          )}
                        </Stack>
                      )}
                    </form>

                    <Typography variant="h4">Хотите подробностей?</Typography>
                    <Typography sx={{ color: "text.secondary", mb: 2 }}>
                      Подпишитесь на нас в телеграм
                      <IconButton
                        color="primary"
                        aria-label="subscribe to telegram"
                        href="https://t.me/your_day_com"
                      >
                        <TelegramIcon />
                      </IconButton>
                    </Typography>
                    <Button
                      variant="outlined"
                      size="large"
                      href="https://t.me/your_day_com"
                    >
                      <TelegramIcon />
                      <Box ml={1}> Наш телеграм</Box>
                    </Button>
                  </Box>
                </Container>
              </RootStyle>
            </Page>

        </ThemeColorPresets>
      </ThemeProvider>

  );
}

export default App;
